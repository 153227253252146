<template>
    <LogoLink class="hidden lg:block" />
    <nav class="hidden lg:flex lg:flex-1 lg:items-center lg:gap-x-12">
        <div
            v-for="blok in data"
            :id="blok.key"
            :key="blok.key"
            class="relative"
        >
            <Menu v-slot="{ open, close }">
                <MenuButton
                    class="group flex w-full items-center justify-between py-3 font-semibold text-grind transition-all hover:text-brew"
                    :class="{
                        'mt-[2px] border-b-2 border-grind hover:border-brew':
                            open,
                    }"
                    ><span class="mr-2 font-bold text-inherit">
                        {{ blok.title }}</span
                    >
                    <span
                        :class="{
                            'lg:rotate-180': open,
                        }"
                        class="-rotate-90 lg:rotate-0"
                    >
                        <Icon
                            name="chevron-down"
                            class="text-sm text-inherit" /></span
                ></MenuButton>
                <FadeTransition>
                    <MenuItems
                        v-show="open"
                        static
                        class="rounded-md bg-white px-6 py-5 shadow-md focus:outline-none lg:absolute lg:left-0 lg:top-[4.25rem] lg:origin-top-left lg:drop-shadow-[0_0_3px_rgba(0,0,0,0.25)]"
                    >
                        <div class="flex gap-x-12">
                            <MenuItem
                                v-for="group in blok.groups"
                                :key="group"
                                as="ul"
                            >
                                <MenuListItem
                                    :group="group"
                                    @link-click="close"
                                />
                            </MenuItem>
                        </div>
                    </MenuItems>
                </FadeTransition>
            </Menu>
        </div>
        <div
            v-if="variation == 'two-button'"
            id="two-button-nav-div"
            class="px-4 lg:flex lg:flex-1 lg:justify-end lg:px-0"
        >
            <ButtonContainer>
                <NuxtLink
                    class="btn-secondary !inline-flex items-center gap-2"
                    to="/design-lab"
                    @click="onNavButtonClick()"
                >
                    <Icon name="flask" class="text-[20px]" />
                    Roastar Design Lab
                </NuxtLink>

                <NuxtLink
                    class="btn-primary mb-4 lg:mb-0"
                    to="/get-quote"
                    @click="onNavButtonClick()"
                >
                    Get a Quote
                </NuxtLink>
            </ButtonContainer>
        </div>
        <div
            v-if="variation == 'single-button'"
            id="design-price-div"
            class="px-4 lg:flex lg:flex-1 lg:justify-end lg:px-0"
        >
            <ButtonContainer>
                <NuxtLink
                    class="btn-primary !inline-flex items-center gap-2"
                    to="/design-and-price"
                    @click="onNavButtonClick()"
                >
                    <Icon name="pencil" class="text-[20px]" />
                    Design & Price
                </NuxtLink>
            </ButtonContainer>
        </div>
    </nav>
</template>

<script setup lang="ts">
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import MenuListItem from '~/layouts/includes/menu/MenuListItem.vue';
import FadeTransition from '~/components/transitions/FadeTransition.vue';
import LogoLink from '~/layouts/includes/menu/LogoLink.vue';

defineProps({
    data: {
        type: Array,
        required: true,
    },
    variation: {
        type: String,
    },
});

const onNavButtonClick = () => {
    window._conv_q = window._conv_q || [];
    _conv_q.push(['triggerConversion', '100485852']);
};
</script>
