<template>
    <TopBar />
    <header
        id="page-header"
        ref="headerRef"
        class="sticky top-0 z-[99998] flex-1 overflow-hidden bg-white shadow-lg lg:h-[100px] lg:flex-none lg:overflow-visible"
    >
        <div
            class="container flex flex-col items-center lg:mx-auto lg:h-full lg:flex-row lg:justify-between lg:gap-x-12"
        >
            <MobileMainMenu :data="data" :variation="convertVariation" />
            <MainMenu :data="data" :variation="convertVariation" />
        </div>
    </header>
</template>

<script setup lang="ts">
import { onMounted } from 'vue';
import {
    useLocalStructuredData,
    useOrganizationStructuredData,
} from '~/composables/useStructuredData';
import MainMenu from '~/layouts/includes/menu/MainMenu.vue';
import MobileMainMenu from '~/layouts/includes/menu/MobileMainMenu.vue';

const { data: data } = await useFetch(`/nuxt-api/global/header`);

const organizationStructuredData = useOrganizationStructuredData();

useServerHead(() => ({
    script: organizationStructuredData,
}));

const localStructuredData = useLocalStructuredData();

useServerHead(() => ({
    script: localStructuredData,
}));

const convertExperimentsLoaded = reactive({ value: false });
let convertCheckStarted: number | null = null;
const convertCheckTimeout = 5000;

const loadConvertExperiments = () => {
    if (convertCheckStarted === null) {
        convertCheckStarted = Date.now();
    }

    if (Date.now() - convertCheckStarted > convertCheckTimeout) {
        convertExperimentsLoaded.value = true;
        return;
    }

    if (!window.convert.currentData) {
        window.setTimeout(loadConvertExperiments, 100);
    } else if (
        Object.keys(window.convert.currentData.experiences).length === 0
    ) {
        window.setTimeout(loadConvertExperiments, 100);
    } else {
        convertExperimentsLoaded.value = true;
    }
};

onMounted(() => {
    if (!window.convert) {
        convertExperimentsLoaded.value = true;
        return;
    }

    loadConvertExperiments();
});

const convertVariation = computed(() => {
    if (!convertExperimentsLoaded.value) {
        return null;
    }

    if (
        window.convert &&
        window.convert.currentData &&
        window.convert.currentData.experiences[1004133364]
    ) {
        if (
            window.convert.currentData.experiences[1004133364].variation.key.includes(
                'original',
            )
        ) {
            return 'two-button';
        } else if (
            window.convert.currentData.experiences[1004133364].variation.key.includes(
                'variation',
            )
        ) {
            return 'single-button';
        }
    }
    console.log("We didn't find a variation, defaulting to two-button");
    return 'two-button';
});
</script>
