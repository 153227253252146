<template>
    <NuxtLink to="/" class="roastar-logo flex-none py-4" title="Roastar">
        <img
            alt="Roastar Logo"
            class="h-8 w-auto lg:h-10"
            src="~/assets/images/roastar_logo.svg"
            width="203"
            height="40"
        />
    </NuxtLink>
</template>
